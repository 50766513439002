.field-name-field-blog-post-main-image {
  text-align: center;
}
.views-field-field-blog-topics {
  
  .views-label {
    font-weight: bold;
  }

  div {
    display: inline-block;
  }

}

.views-field-field-blog-youtube {

  iframe {
    max-width: 100%;
  }

}